import LandingPage from './components/LandingPage';
import { Grid, useMediaQuery } from '@mui/material';

function App() {
  const matches = useMediaQuery('(max-width:430px)');
 
  return (
    <Grid 
      className="container" 
      sx={{
        height: matches ? "1450px" : "96vh"
      }}
    >
      <LandingPage/>    
    </Grid>
  );
}

export default App;
