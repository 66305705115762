import {useState } from "react";
import axios from 'axios'
import {Typography, Box, Select, MenuItem, Grid, useMediaQuery} from '@mui/material';
import CountdownTimer from "./CountDownTimer";
import Footer from "./Footer";

let production = true;
let baseURL = (production) ? 'https://notjustanothercompany.com/' : 'http://localhost/not-just-another-company/landing_page/';

export const Axios = axios.create({
    baseURL: baseURL,
});

const LandingPage = () => {
    const [successMsg, setSuccessMsg] = useState(false);
    const matches = useMediaQuery('(max-width:430px)');
    const [formData, setFormData] = useState({
        full_name:'',
        email:'',
        contact_number:'',
        company: '',
        add_to_mailing_list: '',
        action: 'landing_page'
    });

    // Sets all vars in formData as users types in values
    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

    // Handles the submission of the form.
    const submitForm = (e) => {
        e.preventDefault();

        Axios.post('capture_request.php', formData)
        .then(function (response){
            if(response){
                setSuccessMsg('Welcome! We will be intouch ASAP.');

                setTimeout(function() {
                    window.location.reload();
                }, 2000)
            }
        });
    }

    return (
        
        <Box>
            {/* <span>{`(max-width:430px) matches: ${matches}`}</span>; */}
            <Grid container>
                <Grid item xs={6}> 
                    <Typography 
                        variant="h1"
                        className="heading1"
                        sx={{
                            fontSize: matches ? "25px" : "48px"
                        }}
                    >
                        Not <span className='orange-text'>Just</span> Another Company
                    </Typography>
                    <Typography 
                        variant="h4"
                        className="heading4"
                        sx={{
                            fontSize: matches ? "12px" : "25px"
                        }}
                    >
                        <span className='orange-text'>We are you, you are us, together NJAC</span>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography 
                        variant="h1"
                        className="heading1"
                        sx={{
                            textAlign:"end",
                            fontSize: matches ? "25px" : "48px"
                        }}
                    >
                        Count <span className='orange-text'>Down</span> To Launch
                    </Typography>

                    <Typography 
                        variant="h4"
                        className="heading4"
                        sx={{
                            textAlign:"end",
                            fontSize: matches ? "12px" : "25px"
                        }}
                    >
                        <CountdownTimer />
                    </Typography>
                </Grid>
            </Grid>
            <Box className="myform">
                <Typography
                    variant="h2"
                    className="heading2"
                    sx={{
                        fontSize: matches ? "24px" : "32px"
                    }}
                >
                    Stay <span className='orange-text'>In</span> Touch
                </Typography>

                {successMsg && <div className="success-msg">{successMsg}</div>}

                <form onSubmit={submitForm}>
                    <label htmlFor="full_name">Full Name:</label>
                    <input type="text" name="full_name" placeholder="ex: John Doe" id="full_name" required onChange={onChangeInput}/>

                    <label htmlFor="email">Email:</label>
                    <input type="email" name="email" placeholder="ex: mymail@mail.com" id="email" required onChange={onChangeInput}/>

                    <label htmlFor="contact_number">Contact Number:</label>
                    <input type="text" name="contact_number" placeholder="ex: +27 11 111 1234" id="contact_number" required onChange={onChangeInput}/>

                    <label htmlFor="company">Company:</label>
                    <input type="text" name="company" placeholder="ex: Company Pty Ltd" id="company" required onChange={onChangeInput}/>

                    <label htmlFor="add_to_mailing_list">Add To Mailing List:</label>
                    <Select
                        name="add_to_mailing_list" 
                        id="add_to_mailing_list"
                        sx={{
                            height:"45px",
                            borderRadius:"10px"
                        }}
                        required
                        onChange={onChangeInput}
                        value={formData.add_to_mailing_list}
                    >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </Select>

                    <input type="hidden" name="action" id="action" value="landing_page" />

                    <button type="submit" >Join</button>
                </form>
            </Box>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
                sx={{
                    height: "280px"
                }}
            >
                <Footer />
            </Grid>
        </Box>
    )
}

export default LandingPage;