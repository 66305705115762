import React, { useState, useEffect } from 'react';
import {Typography, Box} from '@mui/material';


const CountdownTimer = () => {
  // Set the target date and time (year, month (0-11), day, hour, minute, second)
  const targetDate = new Date(2023, 10, 31, 24, 0, 0); // September 1, 2023, 12:00:00
  
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(getTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  function getTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds
    };
  }

  return (
    <Box className="orange-text">
        <span className="time">{timeRemaining.days} Days: </span>

        <span className="time">{timeRemaining.hours} Hrs: </span>

        <span className="time">{timeRemaining.minutes} Min: </span>

        <span className="time">{timeRemaining.seconds} Sec</span>
    </Box>
  );
};

export default CountdownTimer;