import {Typography, Box} from '@mui/material';

const Footer = () => {
    return(
        <Box 
            sx={{
                textAlign:"center"
            }}
        >
            <Typography variant="caption" display="block"> <strong>Terms and Conditions </strong> </Typography>
            <Typography variant="caption" display="block">
                    By signing up to our mailing list, you agree to the following terms and conditions:
            </Typography>
            <Typography variant="caption" display="block">
                <strong>Subscription Agreement: </strong>
                By providing your email address and other relevant information, you consent to receiving marketing and promotional emails from Not Just Another Company. You understand that you will be added to our mailing list to receive updates, offers, and other relevant information.
            </Typography>
               
            <Typography variant="caption" display="block">
                <strong>Personal Information: </strong>
                We value your privacy. Your personal information provided during subscription will be used in accordance with our Privacy Policy. We will not share, sell, or rent your information to any third parties without your explicit consent.
            </Typography>

            <Typography variant="caption" display="block">
                <strong>Subscription Consent: </strong>
                You confirm that you have willingly subscribed to our mailing list and understand that you have the option to unsubscribe at any time. Unsubscribing can be done by clicking the "unsubscribe" link provided in the emails.
            </Typography>

            <Typography variant="caption" display="block">
                <strong>Frequency and Content: </strong>
                You acknowledge that the frequency and content of emails sent to our mailing list subscribers may vary. We strive to provide valuable content, promotions, and updates that are relevant to our subscribers' interests.
            </Typography>

            <Typography variant="caption" display="block">
                <strong>Opt-Out Option: </strong>
                If you no longer wish to receive emails from us, you can opt out by clicking the "unsubscribe" link in any of our emails or by contacting our support team at info@notjustanothercompany.com.
            </Typography>

            <Typography variant="caption" display="block">
                <strong>Changes to Terms and Conditions: </strong>
                Not Just Another Company reserves the right to modify or update these terms and conditions at any time. Subscribers will be notified of any changes through email or by visiting our website.
            </Typography>

            <Typography variant="caption" display="block">
                <strong>Contact Information: </strong>
                If you have any questions or concerns regarding your subscription or these terms and conditions, you can contact us at info@notjustanothercompany.com.
            </Typography>

            <Typography variant="caption" display="block">
                By clicking the "Join" button or providing your email address for subscription, you indicate that you have read, understood, and agreed to the terms and conditions outlined above.
            </Typography>
    </Box>
    )
    
}

export default Footer